import { ethers } from "ethers";
import { KYC_CONTRACT_ADDRESS } from "./address";

let KycContractABI = require("./kyc.json");

let kycContract;


export const checkedWallet = async (signer) => {
    try {
        kycContract = getContract(KYC_CONTRACT_ADDRESS, signer);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const ethEnabled = async (signer) => {
    return await checkedWallet(signer);
}

export const getContract = (contractAddress, signer) => {
    return new ethers.Contract(contractAddress, KycContractABI["abi"], signer);
}


export const _getUserInfo = async (address) => {
    try {
        await ethEnabled();
        const res = await kycContract.getUserInfo(address);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const _register = async (user, signer) => {
    try {
        await ethEnabled(signer);
        const tx = await kycContract.register({ ...user });
        return tx;
    } catch (error) {
        console.log(error);
    }
}

export const _update = async (user) => {
    try {
        await ethEnabled();
        const res = await kycContract.update(user);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const _getAllUserInfo = async () => {
    try {
        await ethEnabled();
        const res = await kycContract.getAllUserInfo();
        return res;
    } catch (error) {
        console.log(error);
    }
}
/* institution methods */
export const _isUserRegistered = async (address, provider) => {
    try {
        await ethEnabled(provider);
        const res = await kycContract.isUserRegistered(address);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const _updateUserStatus = async (address, status) => {
    try {
        await ethEnabled();
        const res = await kycContract.updateUserStatus(address, status);
        return res;
    } catch (error) {
        console.log(error);
    }
}