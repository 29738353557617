import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';

// assets
import { InPageConnectButton } from './ConnectButton';
import { Link } from 'react-router-dom';


// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    boxContainer: {
        marginLeft: '100px',
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = () => {
    const classes = useStyles();

    return (
        // <React.Fragment>
        //     {/* logo & toggler button */}
        //     <div className={classes.boxContainer}>
        //         <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
        //             <LogoSection />
        //         </Box>
        //     </div>

        //     <div className={classes.grow} />
        //     <div className={classes.grow} />

        //     {/* notification & connect wallet */}
        //     <InPageConnectButton />
        // </React.Fragment>
        <header className="style_header__gUxsQ">
            <Link to={"/"}>
                <img 
                    src="assets/media/logo.svg" 
                    className="style_logo__QGaEE"
                    alt="Medifakt"
                />
            </Link>
            <div>
                {/* <button
                    class="style_button__VZS2Y style_primary__Y0RZ5 style_large__tgOsk style_uppercase__cctDo">
                    Connect
                </button>  */}
                {/* <input className="style_button__VZS2Y style_primary__Y0RZ5 style_large__tgOsk style_uppercase__cctDo" type="button" value="Connect Wallet"/> */}
                <InPageConnectButton />
            </div>
        </header>        
    );
};


Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
