const ja = {

  CONNECT : "ウォレットを接続する",
  WRONG_NETWORK : "間違ったネットワーク",

  //MAIN PAGE
  FAKT_TOKEN_POOL : "FAKT トークンプール",
  CIRCULATION_SUPPLY : "供給量",
  OF_SUPPLY : "総供給量の",
  OF : "の",
  TOTAL_LOCKED_FAKT : "ロックされたFAKT総量",
  TOTAL_FAKT_STAKED : "ステーキングFAKT総量",
  POOL_APY : "APYプール",
  STAKE_FAKT : "FAKTをステーキング",
  BUY_ON_UNISWAP : "UNISWAPで購入",
  STAKING_FAKT : "FAKTをステーキング",
  CURRENT_FAKT_BAL : "現在のFAKT残高",
  ESTIME_APY : "APY予測",
  AVAILABLE_DEPOSIT : "利用可能デポジット",
  MAX : "最大",
  APPROVE: "承認する",
  STAKE : "ステーキング",
  UNCLAIMED_REWARDS : "報酬が要求されませんでした",
  CLAIM_REWARDS : "報酬を要求する",
  TOTAL_STAKED : "ステーキング総量",
  WITHDRAW_ALL : "全て引き出す",
  MILLION : "万",
  BILLION : "億",

  //FOOTER
  COPYRIGHT : "著作権 ",
  RIGHTS : "全ての権利を留保している",
  DISCLAIMER : "免責事項",
  DISCLAIMER_CONTENT : "免責事項の内容",
  DISCLAIMER_CONTENT : "このウェブサイトまたは他の公式チャンネル上のすべての情報は、情報提供のみを目的として公開されており、機関投資家および洗練された個人投資家のみを対象としています。今後提供されるサービスは、それに関連する法的契約の条件に従うものとします。このサイトのいかなるものも、Medifakt ネットワークまたはその他の人物による投資の推奨として解釈されるべきではありません。Medifaktネットワークおよびその関連サービスは、米国の個人には提供されません。Medifaktネットワークは、Polkadot のデータ経済を強化するクロスチェーン プラットフォームの構築を目指しています。",
    
  //MESSAGES - STAKING:
    msg1 : "",
    msg2 : "",
    msg3 : "",
    msg4 : "",
    msg5 : "",
    msg6 : "",
    msg7 : "",
    msg8 : "",
    msg9 : "",
    msg10 : "",




  
  }
  export default ja;