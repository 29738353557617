
export const initialState = {
  userDetails: {
    sex: 'male',
    age: 20,
    maritalStatus: true,
    height: 0,
    weight: 0,
    profession: '',
    smoking: true,
    insurancePlan: true, // Address of the hospital only if KYC gets verified
    dataHash: '', // Documents will be stored in decentralised storage & a hash will be created for the same
    dataUpdatedOn: Date.now(),
    active: 1
  },
  isUserRegistered: false,
  isUserLoggedIn: false,
  loading: false,
  userList: []
};

