import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router , Route, Switch, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import { useProvider } from 'wagmi';
import { useApi } from '../hooks/useApi';


// dashboard routing
const Home = Loadable(lazy(() => import('../views/Home')));
const Staking = Loadable(lazy(() => import('../views/Staking')));



//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    const { isConnected, address } = useAccount();

    // const { isUserRegistered } = useApi();
    const provider = useProvider()

    // useEffect(() => {
    //     if (isConnected) {
    //         isUserRegistered(address, provider);
    //     }
    // }, [isConnected, address, provider]);

    return (
        <Route path={['/']}>
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/" component={Home} />
                    <Route path="/stake" component={Staking} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
