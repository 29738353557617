import { useCallback, useState } from "react";
import {
    setUserList,
    setUserData,
    useAuthContext,
    setUserRegistered
} from "../contexts/auth-context";
import {
    _getUserInfo,
    _register,
    _update,
    _getAllUserInfo,
    _isUserRegistered,
    _updateUserStatus
} from '../blockchain/kycContract'

import { toastError, toastSuccess } from "../utils";

const rejectErrorCheck = (error) =>
    error.message.includes("User denied transaction signature");

export function useApi() {
    const { dispatch } = useAuthContext();
    const [txPending, setTxPending] = useState(false);
    const [txHash, setTxHash] = useState('');

    const getUserInfo = useCallback(async (address) => {
        try {
            setTxPending(true);
            const info = await _getUserInfo(address);
            dispatch(setUserData(info));
        } catch (error) {
            console.log(error);
            toastError("Error while fetching user information");
        } finally {
            setTxPending(false);
        }
    }, []);

    const register = useCallback(async (userInfo, signer) => {
        try {
            setTxPending(true);
            let registerTx = await _register(userInfo, signer);
            await registerTx.wait();
            setTxHash(registerTx.hash);
            toastSuccess("User Information Successfully Registered, This will reflect soon");
            return true;
        } catch (error) {
            if (rejectErrorCheck(error)) {
                toastError("User has rejected the action");
            } else {
                toastError("Error while regist user, please try again later!");
            }
            return false;
        } finally {
            setTxPending(false);
        }
    }, []);

    const update = useCallback(async (user) => {
        try {
            await _update(user);
            toastSuccess("User info updated successfully, it will reflect in few minutes!");
        } catch (error) {
            console.log(error);
            if (rejectErrorCheck(error)) {
                toastError("User has rejected the action");
            } else {
                toastError("Error while update user info, please try again later!");
            }
        }
    }, []);

    const getAllUserInfo = useCallback(async () => {
        try {
            const data = await _getAllUserInfo();
            dispatch(setUserList(data));
            toastSuccess('fetch user list successfully');
            return data;
        } catch (error) {
            console.log(error);
            if (rejectErrorCheck(error)) {
                toastError("User has rejected the action");
            } else {
                toastError("Error while fetch userlist, Please try later!");
            }
        }
    }, []);

    // hospital functions

    const isUserRegistered = useCallback(async (address, provider) => {
        try {
            const res = await _isUserRegistered(address, provider);
            dispatch(setUserRegistered(res));
        } catch (error) {
            console.log(error);
        }
    }, []);

    const updateUserStatus = useCallback(async (address, status) => {
        try {
            await _updateUserStatus(address, status);
        } catch (error) {
            console.log(error);
            toastError("Error while update user status");
        }
    }, []);


    return {
        txPending,
        txHash,
        getUserInfo,
        register,
        update,
        getAllUserInfo,
        isUserRegistered,
        updateUserStatus
    };
}
