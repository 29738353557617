import { SET_USER_REGISTERED, SET_LOADING, SET_USER_LIST, SET_USER_LOGIN, SET_USER_DATA } from "../action-types";

export function setLoginStatus(payload) {
  return {
    type: SET_USER_LOGIN,
    payload
  };
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    payload
  };
}

export function setUserList(payload) {
  return {
    type: SET_USER_LIST,
    payload
  };
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload
  }
}

export function setUserRegistered(payload) {
  return {
    type: SET_USER_REGISTERED,
    payload
  }
}

