import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconLetterM } from '@tabler/icons';
import { language } from "../pages/language";




// const useStyles = makeStyles((theme) => ({
//     footer: {
//         display: 'flex',
//         justifyContent: 'center',
//         padding: '20px',
//         '& .MuiTypography-root': {
//             color: "rgba(0, 0, 0, 0.75)",
//             paddingRight: '20px',
//             fontFamily: 'square',
//             fontSize: '14px',
//             fontWeight: 500
//         }
//     }
// }))


var localStorageLang = localStorage.getItem("lang");
if (localStorageLang === null) {
  localStorageLang = "Japanese";
}



const Footer = () => {

    return (
        <React.Fragment>
               <div className="row" style={{marginLeft:60 , marginRight : 60}}>
                    <Grid container>
                      <Grid item xs={4}>
                        <div> 
                            <Link to={"/"}>
                             <img 
                                src="assets/media/logo.svg" 
                                className="style_logo__QGaEE"
                                alt="Medifakt"
                             />
                            </Link>
                        </div>
                        <br/>
                        <h3>Medifakt © {language[localStorageLang].COPYRIGHT} 2022.{language[localStorageLang].RIGHTS}</h3>
                        <div className='icon-separate'>
                        <Link to={{pathname:"https://t.me/MedifaktOfficial"}} target="_blank"><span fontSize="large" style={{color:"white"}}><TelegramIcon/></span> </Link>
                        <Link to={{pathname:"https://twitter.com/medifakt"}}  target="_blank"><span fontSize="large" style={{color:"white"}}><TwitterIcon/></span> </Link>
                        <Link to={{pathname:"https://medifakt.medium.com/"}}  target="_blank"><span fontSize="large" style={{color:"white"}}><IconLetterM/></span></Link>
                        </div>
                      </Grid>
                      <Grid item xs={7} style={{marginLeft : 20}} >
                        <h3> {language[localStorageLang].DISCLAIMER}  </h3>
                        <h5>{language[localStorageLang].DISCLAIMER_CONTENT} </h5>
                      </Grid>
                    </Grid>
            </div>
        </React.Fragment>
    )
}

export default Footer;