const en = {
    // HEADER PAGE
    CONNECT : "Connect Wallet",
    WRONG_NETWORK : "Wrong Network",

    //MAIN PAGE
    FAKT_TOKEN_POOL : "FAKT Token Pool",
    CIRCULATION_SUPPLY : "Circulating Supply",
    OF_SUPPLY : "of Total Supply",
    OF : "Of",
    TOTAL_LOCKED_FAKT : "Total FAKT Locked",
    TOTAL_FAKT_STAKED : "Total FAKT Staked",
    POOL_APY : "Pool APY",
    STAKE_FAKT : "stake FAKT on pool",
    BUY_ON_UNISWAP : "buy FAKT on uniswap",
    STAKING_FAKT : "Stake FAKT to earn FAKT",
    CURRENT_FAKT_BAL : "Current FAKT Balance",
    ESTIME_APY : "Estimated APY",
    AVAILABLE_DEPOSIT : "Available to deposit",
    MAX : "MAX",
    APPROVE: "Approve",
    STAKE : "Stake",
    UNCLAIMED_REWARDS : "Unclaimed Rewards",
    CLAIM_REWARDS : "claim reward",
    TOTAL_STAKED : "Total Staked",
    WITHDRAW_ALL : "Withdraw All",
    MILLION : "Million",
    BILLION : "Billion",

    //FOOTER
    COPYRIGHT : "Copyright ",
    RIGHTS : "All Rights Reserved",
    DISCLAIMER : "Disclaimer",
    DISCLAIMER_CONTENT : "All the information on this website or other official channels is published for information purposes only and is only intended for institutional investors and sophisticated individual investors. Any services to be provided in the future will be subject to the terms of the legal agreements relating thereto. Nothing on this Site should be interpreted as the investment endorsement by Medifakt Network or any other person. Medifakt Network and its related services are not provided to any individual from the United States. Medifakt Network aims to build a cross-chain platform powering the data economy on Polkadot.",

    //MESSAGES - STAKING:
    msg1 : "You cannot stake 0",
    msg2 : "You cannot exceed your Balance Limit",
    msg3 : "Successfully Approved",
    msg4 : "Error in Approving , Please try again",
    msg5 : "Claimed Rewards Successfully",
    msg6 : "Staked Amounts Claimed  Successfully",
    msg7 : "Error in Fetching Staked FAKT , Please try again",
    msg8 : "Staking Amount should be equal to :",
    msg9 : "Successfully Staked",
    msg10 : "Your Staked Amount should be equal to ",

   


  
  }
  export default en;