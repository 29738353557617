import { SET_USER_REGISTERED, SET_LOADING, SET_USER_LIST, SET_USER_LOGIN, SET_USER_DATA } from "../action-types";

export function authReducer(
  state,
  action
) {
  switch (action.type) {
    case SET_USER_LOGIN:
      return { ...state, isUserLoggedIn: action.payload };

    case SET_LOADING:
      return { ...state, loading: action.payload };

    case SET_USER_DATA:
      return {
        ...state,
        userDetails: { ...action.payload }
      };

    case SET_USER_LIST: {
      return {
        ...state,
        userList: action.payload
      };
    }

    case SET_USER_REGISTERED: {
      return {
        ...state,
        isUserRegistered: action.payload
      };
    }

    default:
      return state;
  }
}
export default authReducer;
