import React,{CSSProperties , useEffect , useState} from 'react';
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { ConnectButton as RainbowConnectButton  } from "@rainbow-me/rainbowkit";
import { makeStyles } from "@material-ui/styles";
import Select from "react-select";
import { language } from "../../pages/language";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";





const useStyle = makeStyles(() => ({
    button: {
        width: '177px',
        height: '50px',
        borderRadius: '75px',
        fontFamily: 'square',
        color: "#FFF",
        backgroundColor: "#745196",
        boxShadow: 0,
        '&:hover': {
            color: "#FFF",
            backgroundColor: "#745196"
        }
    } , 
   
}));

    const colourStyles = {
        placeholder: (styles) => ({
            ...styles,
            color: '#FFF'
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#745196",
            borderRadius: '75px',
            width: '102px',
            height: '50px',
            fontFamily: 'square',
            color: "#FFF",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#FFF'
        }),
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: "#FFF",
                borderRadius: '500px',
                color: "black",
                cursor: isDisabled ? "not-allowed" : "default"
            };
        },
        
    };
  

export const InPageConnectButton = () => {
    const options = [
        { value: "English", label: "EN" },
        { value: "Japanese", label: "日本語" },
      ];
    
    const [select, setSelectedOption] = useState(options[0].value);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };


    const theme = useTheme();
    const classes = useStyle();
    const upXs = useMediaQuery(theme.breakpoints.up('sm'));
    const buttonView = useMediaQuery(theme.breakpoints.up('xs'));



    var localStorageLang = localStorage.getItem("lang");
    if (localStorageLang === null) {
      localStorageLang = "Japanese";
    }
    
   

    const handleChange = (SelectedOption) => {
        window.location.reload(true)
        setSelectedOption(SelectedOption.value);
        localStorage.setItem("lang", SelectedOption.value);
        // navigate("/", {replace:true});
        window.scrollTo(0,0);
      };


   


    return (
        <RainbowConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted
            }) => {
                return (
                    <div
                        {...(!mounted && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none'
                            }
                        })}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return (
                                    <>
                                    <div style={{ display: 'flex', gap: 12 }}>
                                    {/* Over all display of Language Select */}

                                    <Select
                                            styles={colourStyles}
                                            value={select.value}
                                            width='200px'
                                            options={options}
                                            onChange={handleChange}
                                            placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                            className="select-box-translation"
                                            
                                        />
                                    {/* This Box Component defines if not connected and what buttons to display in Desktop Mode */}
                                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                                <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                     {language[localStorageLang].CONNECT}
                                                 </Button>
                                     </Box>
                                    {/* This Container Component defines if not connected  and what buttons to display in Mobile Mode */}
                                    <Container maxWidth="xl">
                                    <Toolbar disableGutters>
                                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit"
                                          >
                                            <MenuIcon />
                                          </IconButton>
                                          <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorElNav}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left"
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left"
                                                }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{
                                                  display: { xs: "block", md: "none" }
                                                }}
                                              >
                                                  <MenuItem onClick={handleCloseNavMenu}>
                                                    <Typography textAlign="center" component="span">
                                                        <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                         {language[localStorageLang].CONNECT}
                                                        </Button>
                                                    </Typography>
                                                  </MenuItem>
                                              </Menu>
                                              
                                            {/* <div style={{ display: 'flex', gap: 12 }}>
                                                 <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                     {language[localStorageLang].CONNECT}
                                                 </Button>
                                                 { upXs &&
                                                 <Select
                                                   styles={colourStyles}
                                                   value={select.value}
                                                   width='200px'
                                                   options={options}
                                                   onChange={handleChange}
                                                   placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                                   variant='contained'
                                                 />
                                                  }
                                     
                                         </div> */}
                                         </Box>
                                        </Toolbar>
                                     </Container>
                                     </div>
                                    </>
                                    
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <Button className={classes.button} onClick={openChainModal} variant="contained">
                                        {language[localStorageLang].WRONG_NETWORK}
                                    </Button>
                                );
                            }

                            return (
                                <div style={{ display: 'flex', gap: 12 }}>
                                     {/* Over all display of Language Select */}
                                     <Select
                                      styles={colourStyles}
                                      value={select.value}
                                      placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                      options={options}
                                      onChange={handleChange}
                                      className="select-box-translation"
                                    />
                                    {/* This Box Component defines if connection and what buttons to display in Desktop Mode */}
                                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex"} }}>
                                         <Button
                                            onClick={openChainModal}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            className={classes.button}
                                            variant='contained'
                                        >
                                            {chain.name}
                                        </Button>
                                        <Button onClick={openAccountModal} className={classes.button} variant='contained' style={{marginLeft:10}}>
                                            {account.displayName}
                                        </Button>
                                     </Box>
                                     {/* This Container defines what buttons to display in mobile mode after successfull connection */}
                                     <Container maxWidth="xl">
                                    <Toolbar disableGutters >
                                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit"
                                          >
                                            <MenuIcon />
                                          </IconButton>
                                          <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorElNav}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left"
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left"
                                                }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{
                                                  display: { xs: "block", md: "none" }
                                                }}
                                                
                                              >
                                                 {/* here earlier i added color black */}
                                                  <MenuItem onClick={handleCloseNavMenu}> 
                                                    <Typography textAlign="center" component="span">
                                                    <Button
                                                       onClick={openChainModal}
                                                       style={{ display: 'flex', alignItems: 'center',  }}
                                                       className={classes.button}
                                                       variant='contained'
                                                       
                                                   >
                                                       {chain.name}
                                                   </Button>
                                                   <div className='spacer-single'></div>
                                                   <Button onClick={openAccountModal}  className={classes.button} variant='contained'>
                                                      {account.displayName}
                                                     </Button>
                                                    </Typography>
                                                  </MenuItem>
                                              </Menu>
                                              
                                            {/* <div style={{ display: 'flex', gap: 12 }}>
                                                 <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                     {language[localStorageLang].CONNECT}
                                                 </Button>
                                                 { upXs &&
                                                 <Select
                                                   styles={colourStyles}
                                                   value={select.value}
                                                   width='200px'
                                                   options={options}
                                                   onChange={handleChange}
                                                   placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                                   variant='contained'
                                                 />
                                                  }
                                     
                                         </div> */}
                                         </Box>
                                        </Toolbar>
                                     </Container>
                                    {/* {
                                        upXs &&
                                        <Button
                                            onClick={openChainModal}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            className={classes.button}
                                            variant='contained'
                                        >
                                            {chain.name}
                                        </Button>
                                    }
                                    <Button onClick={openAccountModal} className={classes.button} variant='contained'>
                                        {account.displayName}
                                      
                                    </Button>
                                     */}
                                   
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </RainbowConnectButton.Custom>
    );
};

