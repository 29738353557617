import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';

// project imports
import config from './../config';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    return (
        <Switch>
            {/* <Redirect exact from="/" to={config.defaultPath} /> */}
            <React.Fragment>
                {/* Routes for main layouts */}
                <MainRoutes />
                <ToastContainer limit={1}/>
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
