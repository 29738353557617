import 'react-circular-progressbar/dist/styles.css';
import React, { useEffect, useState , CSSProperties , useCallback } from 'react';
import { chain, useAccount, useNetwork, useProvider } from 'wagmi';
import { useConnectModal, useChainModal } from '@rainbow-me/rainbowkit';
import { useSigner } from 'wagmi';
import { useApi } from '../hooks/useApi';
import { useAuthContext } from '../contexts/auth-context';
import faktAbi from "../blockchain/fakt.json";
import stakeAbi from "../blockchain/staking.json";
import { FAKT_CONTRACT_ADDRESS  , STAKING_CONTRACT_ADDRESS} from "../blockchain/address";
import Web3 from "web3";
import { toastError, toastSuccess } from "../utils";
import util from '../utils/web3'
import { language } from '../layout/MainLayout/pages/language';
import { ethers } from "ethers";



const MidDataDisplay = () => {

    const [totalStakeBal, setTotalStakeBal] = useState(0);
    const [totalStakePercentage, setTotalStakePercentage] = useState(0);
    const [totalStakePercentageBal, setTotalStakePercentageBal] = useState(3250000000);
    const [totalFaktCirculatingBal, setTotalFaktCirculatingBal] = useState(3250000000);
    const [totalFaktCirculatingPercentage, setTotalFaktCirculatingPercentage] = useState(0);
    const [totalFaktLockedBal, setTotalFaktLockedBal] = useState(7300000000);
    const [totalFaktLockedPercentage, setTotalFaktLockedPercentage] = useState(0);
    const [maxFaktBal , setMaxFaktBal] = useState(9000000000)
    const [isLoading, setIsLoading] = useState(false);


    const { isConnected, isDisconnected , address  } = useAccount();
    const { chain: currentChain } = useNetwork();
    const provider = useProvider();
    const { openConnectModal } = useConnectModal();
    const { openChainModal } = useChainModal();
    const { data: signer } = useSigner();
    const { register } = useApi();
    const { state } = useAuthContext();
    const handleClose3 = () => isLoading(false);


    //3250000000 - 3.25 Billion

    useEffect(() => {
        getTotalFaktStaked()
        calculations()
     }, [getTotalFaktStaked,calculations]);

     var localStorageLang = localStorage.getItem("lang");
     if (localStorageLang === null) {
       localStorageLang = "Japanese";
     }

     let stakeContract;

     const getContract = (contractAddress ,abi ,  signer) => {
        return new ethers.Contract(contractAddress, abi , signer);
    }

     async function calculations(){
        var circulatingBalanceDisplay = ( totalFaktCirculatingBal / maxFaktBal ) * 100
        setTotalFaktCirculatingPercentage(circulatingBalanceDisplay.toFixed(2))

        var faktLocked = ( totalFaktLockedBal / maxFaktBal ) * 100 
        setTotalFaktLockedPercentage (faktLocked.toFixed(2))
     }

     const key = "db9df9ef06c0479c990049b29a269ba9";
     
     async function getTotalFaktStaked(){
        try {
            let web3;
            if(window.ethereum){
                 web3 = new Web3(Web3.givenProvider);
            } else {
                 web3 = new Web3(provider.chains[0].rpcUrls.infura +"/"+ key);
            }
            //  console.log(web3)
            // stakeContract =  getContract(STAKING_CONTRACT_ADDRESS, stakeAbi , signer);
            var contractInstance = new web3.eth.Contract(stakeAbi , STAKING_CONTRACT_ADDRESS)
            contractInstance.methods.total_staked().call()
            .then(stakedbal => {
                const bal = (stakedbal/1e18).toFixed(3).replace(/([0-9]+(\.[1-9]+)?)(\.?0+$)/,"$1");
                const faktStaked = (bal/1e6).toFixed(4);
                setTotalStakeBal(faktStaked)
                var stakedPercentConversion = (bal/totalStakePercentageBal)* 100
                setTotalStakePercentage(stakedPercentConversion.toFixed(6))
            }).catch(err => {
                // console.log(err)
                // toastError("Error in Fetching Total Staked FAKT , Please try again")
            })
        } catch(error){
            console.log(error)
        }
     
    }
  return (
    <>
    
       <div className="style_wrap__cE9CJ">
                    <div className="style_inner__uDTI6">
                        <div className="style_ball1__m-CYV"></div>
                        <div className="style_ball2__3AhpE"></div>
                        <div className="style_ball3__S-NB4"></div>
                    </div>
                </div>
                <section className="style_top__1D1NJ">
                    <div className="style_topItem__ZmfzG">
                        <div className="style_topTitle__mxtll">{language[localStorageLang].CIRCULATION_SUPPLY}</div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">{totalFaktCirculatingBal/1e10} {language[localStorageLang].BILLION}</span><span
                                className="style_topDesc__cL25r"> / {maxFaktBal/1e10}  {language[localStorageLang].BILLION}</span></div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">{totalFaktCirculatingPercentage}%</span><span
                                className="style_topDesc__cL25r"> {language[localStorageLang].OF_SUPPLY}</span></div>
                    </div>
                    <div className="style_topItem__ZmfzG">
                        <div className="style_topTitle__mxtll">{language[localStorageLang].TOTAL_FAKT_STAKED}</div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">{totalStakeBal}  {language[localStorageLang].MILLION}</span><span
                                className="style_topDesc__cL25r"> / {totalStakePercentageBal/1e10}  {language[localStorageLang].BILLION}</span></div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">{totalStakePercentage}%</span><span
                                className="style_topDesc__cL25r"> {language[localStorageLang].OF} {language[localStorageLang].CIRCULATION_SUPPLY}</span></div>
                    </div>
                    <div className="style_topItem__ZmfzG">
                        <div className="style_topTitle__mxtll">{language[localStorageLang].TOTAL_LOCKED_FAKT}</div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">{totalFaktLockedBal/1e10}  {language[localStorageLang].BILLION}</span><span
                                className="style_topDesc__cL25r"> / {maxFaktBal/1e10}  {language[localStorageLang].BILLION}</span></div>
                        <div className="style_topRow__ahIoU"><span className="style_topValue__pjKCp">73.1%</span><span
                                className="style_topDesc__cL25r"> {language[localStorageLang].OF_SUPPLY} </span></div>
                    </div>
                </section>
    </>
  )
}

export default MidDataDisplay
