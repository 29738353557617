import { toast } from "react-toastify";

export const toastSuccess = (message) => {
    return toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 4000,
        draggablePercent: 60
    });
};

export const toastError = (message) => {
    return toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 4000,
        draggablePercent: 60
    });
};
