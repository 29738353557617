import { Button, Container, Grid } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import React, { useEffect, useState } from 'react';
import { chain, useAccount, useNetwork, useProvider } from 'wagmi';
import { useConnectModal, useChainModal } from '@rainbow-me/rainbowkit';
import { useSigner , useBalance } from 'wagmi';
import { useApi } from '../hooks/useApi';
import { useAuthContext } from '../contexts/auth-context';
import { toastError, toastSuccess } from "../utils";
import faktAbi from "../blockchain/fakt.json";
import stakeAbi from "../blockchain/staking.json";
import { FAKT_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS} from "../blockchain/address";


import Header from '../layout/MainLayout/Header';
import Footer from '../layout/MainLayout/Footer';
import { Link } from 'react-router-dom';
import { ethers } from "ethers";
import Web3 from "web3";
import MidDataDisplay from './MidDataDisplay';
import { language } from '../layout/MainLayout/pages/language';




const Home = () => {

    const [faktbal, setfaktbal] = useState(0);
    const [stakeBal, setStakeBal] = useState(0);

    const { isConnected, isDisconnected , address  } = useAccount();
    const { chain: currentChain } = useNetwork();
    const provider = useProvider();
    const { openConnectModal } = useConnectModal();
    const { openChainModal } = useChainModal();
    const { data: signer } = useSigner();
    const { register  } = useApi();
    const { state } = useAuthContext();

    var localStorageLang = localStorage.getItem("lang");
    if (localStorageLang === null) {
      localStorageLang = "Japanese";
    }


    const { data, isError, isLoading } = useBalance({
        addressOrName: address,
        chainId: currentChain?.id,
      })

      useEffect( () => {
        if(isConnected){
            getTokenBal(address)
            getStakedAmount(address)

        }
      },[getTokenBal ,getStakedAmount , address , isConnected ])

    let stakeContract;

    const getContract = (contractAddress , abi , signer) => {
         return new ethers.Contract(contractAddress, abi, signer);
     }


    async function getStakedAmount(address){
      
        try{
            stakeContract =  getContract(STAKING_CONTRACT_ADDRESS, stakeAbi , signer);
            stakeContract.getDepositInfo(address)
            .then(stakedbal => {
                // console.log("stakedbal : ",stakedbal)
                const bal = (stakedbal._stake/1e18).toFixed(5).replace(/([0-9]+(\.[1-9]+)?)(\.?0+$)/,"$1");
                if(bal > 0){
                    setStakeBal(bal)
                }
            }).catch(err => {
                // console.log(err)
                // toastError("Error in Fetching Staked FAKT , Please try again")
            })
        }catch(error){
            console.log(error)
        }
    }


    async function getTokenBal(address){
        try{
            
            stakeContract =  getContract(FAKT_CONTRACT_ADDRESS, faktAbi , signer);
            stakeContract.balanceOf(address)
            .then(faktba => {
                if(faktba == "0" || faktba == 0 || faktba == "undefined" || faktba == ""){
                    // toastError("Your FAKT Token balance is : 0 , Please add some FAKT balance to start Staking")
                }
                // console.log(faktba)
                const bal = (faktba/1e18).toFixed(5);
                setfaktbal(bal)
            }).catch(err => {
                // console.log(err)
                // toastError("Error in Fetching FAKT , Please try again")
            })
        }catch(error){
            console.log(error)
        }
      
    }

    const ETH_UNISWAP = "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc3ffbe26e9446ac52008ffc8c1191d60a12bdc48&chain=mainnet";
    const MATIC_UNISWAP = "https://app.uniswap.org/#/swap?inputCurrency=MATIC&outputCurrency=0xD0Ca1F7E82c63c8BE543cDc8B374282870856776&chain=polygon"


    return (
        <React.Fragment>
            <Container>
                  <main className="style_main__jG4+7">
                    <MidDataDisplay />
                <section className="style_pools__k5NOn">
                    <div className="style_card__eUDeP">
                        <div className="style_title__7tkYx">{language[localStorageLang].FAKT_TOKEN_POOL}</div>
                        <div className="style_row__qGixQ"><img src="assets/media/fakt.png" alt=""/>
                            <div className="style_rowValue__k+24s">{language[localStorageLang].POOL_APY}<br/>13.5%</div>
                        </div>
                        <div className="style_btns__IpcHd">
                            {faktbal > 0 || stakeBal > 0 && isConnected ? (
                            <button
                                  className="style_button__VZS2Y style_secondary__BPDfr style_large__tgOsk"
                                  onClick={()=> window.open("/stake", "_self")}><img src="assets/media/stake1.svg"
                                      alt="" width="16"/><span>{language[localStorageLang].STAKE_FAKT}</span>
                          </button>
                            )  : (
                            <button
                                className="style_button__VZS2Y style_secondary__BPDfr style_large__tgOsk"
                                disabled
                                onClick={()=> window.open("/stake", "_self")}><img src="assets/media/stake1.svg"
                                    alt="" width="16"/><span>{language[localStorageLang].STAKE_FAKT}</span>
                            </button>
                            )}
                            {isConnected == true && (
                                  <a 
                                  href={ currentChain?.id == "5" || currentChain?.id == "1" ? ETH_UNISWAP : MATIC_UNISWAP }
                                  target="_blank">
                                  <button
                                      className="style_button__VZS2Y style_primary__Y0RZ5 style_large__tgOsk">
                                      <img src="assets/media/stake2.svg" alt="" width="26"/>
                                      <span>{language[localStorageLang].BUY_ON_UNISWAP}</span>
                                  </button>
                              </a>
                            )}
                            {isConnected == false && (
                                  <a 
                                  href={ETH_UNISWAP}
                                  target="_blank">
                                  <button
                                      className="style_button__VZS2Y style_primary__Y0RZ5 style_large__tgOsk">
                                      <img src="assets/media/stake2.svg" alt="" width="26"/>
                                      <span>{language[localStorageLang].BUY_ON_UNISWAP}</span>
                                  </button>
                              </a>
                            )}
                          
                        </div>
                    </div>
                </section>
            </main>
            </Container >
        </React.Fragment >
    )
}

export default Home;